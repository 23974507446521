import "./Header.css";
import logo from "../../../assets/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  var isTraningSubNavListOpen = false;
  var isNjamounSubNavListOpen = false;
  let navigate = useNavigate();

  function nav(route) {
    navigate(route);
    document.getElementById("home-ul").style.left = "-300px";
  }
  function toggleMenu(action) {
    if (action === "open") document.getElementById("home-ul").style.left = "0";
    else document.getElementById("home-ul").style.left = "-300px";
  }
  function toggleTrainingSubMenu() {
    if (isTraningSubNavListOpen) {
      document.getElementById("home-ul-formations").style.display = "none";
      isTraningSubNavListOpen = false;
    } else {
      document.getElementById("home-ul-formations").style.display = "initial";
      isTraningSubNavListOpen = true;
    }
  }
  function toggleNjamounSubMenu() {
    if (isNjamounSubNavListOpen) {
      document.getElementById("home-ul-njamoun").style.display = "none";
      isNjamounSubNavListOpen = false;
    } else {
      document.getElementById("home-ul-njamoun").style.display = "initial";
      isNjamounSubNavListOpen = true;
    }
  }
  return (
    <div>
      <header className="header-container">
        <div
          style={{
            height: 30,
            backgroundColor: "#593DA1",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "#FA7236",
          }}
        ></div>
        <div className="header-nav-container">
          <div
            className="header-nav"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: 20,
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={logo}
              alt="Logo"
              height={72}
              onClick={() => {
                nav("/");
              }}
            />
            <div
              id="menu-btn"
              onClick={() => {
                toggleMenu("open");
              }}
              for="open-menu-btn"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <hr color="#000000" width="10px" style={{ margin: 0 }}></hr>
                <hr
                  color="#000000"
                  width="10px"
                  style={{ marginTop: 2, marginInline: 0, marginBottom: 2 }}
                ></hr>
                <hr color="#000000" width="10px" style={{ margin: 0 }}></hr>
              </div>
              <span
                style={{ fontSize: 15, fontFamily: "Days One", marginLeft: 6 }}
              >
                MENU
              </span>
            </div>

            <ul className="home-ul" id="home-ul">
              <li
                className="home-li"
                onClick={() => {
                  toggleMenu("close");
                }}
              >
                FERMER LE MENU
              </li>
              <li
                className="home-li"
                onClick={() => {
                  nav("/");
                }}
              >
                ACCUEIL
              </li>
              <li
                className="home-li"
                onClick={() => {
                  toggleNjamounSubMenu();
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>CENTRE NJA'MOUN</span>
                  <span>+</span>
                </div>
              </li>
              <ul id="home-ul-njamoun" style={{ display: "none" }}>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("about-us");
                  }}
                >
                  QUI SOMME NOUS
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("vision-mission");
                  }}
                >
                  VISION & MISSION
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("goals");
                  }}
                >
                  OBJECTIFS
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("calendar");
                  }}
                >
                  CALENDRIER ACADEMIQUE
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("album");
                  }}
                >
                  ALBUM NJA'MOUN
                </li>
              </ul>
              <li
                className="home-li"
                onClick={() => {
                  toggleTrainingSubMenu();
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>FORMATIONS</span>
                  <span>+</span>
                </div>
              </li>
              <ul id="home-ul-formations" style={{ display: "none" }}>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("building-industry");
                  }}
                >
                  INDUSTRIES DU BÂTIMENT ET HABITAT DURABLE
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("creative-industry");
                  }}
                >
                  INDUSTRIES CRÉATIVES, ARTISANALES ET TOURISMES
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("pastoral-industry");
                  }}
                >
                  AGRO BUSINESS
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("technology-industry");
                  }}
                >
                  TECHNOLOGIES DU FUTURE ET INNOVATION
                </li>
                <li
                  className="home-li-formations"
                  onClick={() => {
                    nav("pre-registration");
                  }}
                >
                  MODALITÉS D'INSCRIPTIONS
                </li>
              </ul>
              <li
                className="home-li"
                onClick={() => {
                  nav("/unite-incubation");
                }}
              >
                UNITE D'INCUBATION
              </li>
              <li
                className="home-li"
                onClick={() => {
                  nav("news");
                }}
              >
                ACTUALITES
              </li>
              <li
                className="home-li"
                onClick={() => {
                  toggleMenu("close");
                }}
              >
                <Link to="contact">CONTACT</Link>
              </li>
            </ul>

            <ul className="home-ul-large">
              <li className="home-li-large">
                <Link to="/">ACCUEIL</Link>
              </li>
              <li className="home-li-large-c">
                CENTRE NJA'MOUN
                <ul className="dropdown-1">
                  <li className="home-li-dropdown">
                    <Link className="home-li-dropdown-link" to="about-us">
                      QUI SOMME NOUS
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link className="home-li-dropdown-link" to="vision-mission">
                      VISION & MISSION
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link className="home-li-dropdown-link" to="goals">
                      OBJECTIFS
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link className="home-li-dropdown-link" to="calendar">
                      CALENDRIER ACADEMIQUE
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link className="home-li-dropdown-link" to="album">
                      ALBUM NJA'MOUN
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="home-li-large-f">
                FORMATIONS
                <ul className="dropdown-2">
                  <li className="home-li-dropdown">
                    <Link
                      className="home-li-dropdown-link"
                      to="building-industry"
                    >
                      INDUSTRIES DU BÂTIMENT ET HABITAT DURABLE
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link
                      className="home-li-dropdown-link"
                      to="creative-industry"
                    >
                      INDUSTRIES CRÉATIVES, ARTISANALES ET TOURISMES
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link
                      className="home-li-dropdown-link"
                      to="pastoral-industry"
                    >
                      AGRO BUSINESS
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link
                      className="home-li-dropdown-link"
                      to="technology-industry"
                    >
                      TECHNOLOGIES DU FUTURE ET INNOVATION
                    </Link>
                  </li>
                  <li className="home-li-dropdown">
                    <Link
                      className="home-li-dropdown-link"
                      to="pre-registration"
                    >
                      MODALITÉS D'INSCRIPTIONS
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="home-li-large">
                <Link to="/unite-incubation">UNITE D'INCUBATION</Link>
              </li>
              <li className="home-li-large">
                <Link to="news">ACTUALITES</Link>
              </li>
              <li className="home-li-large">
                <Link to="contact">CONTACT</Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
