import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import "./stagiaires.css";

function Stagiaires() {
  let { id } = useParams();

  const [video, setVideo] = useState(null);

  const [title, setTitle] = useState("");

  const [content, setContent] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingFailed, setIsLoadingFailed] = useState(false);

  async function getNews() {
    const url = "https://apis.njamoun.org/api/news/" + id;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`Response status: ${response.status}`);
      } else {
        const json = await response.json();
        console.log(json);
        setVideo(json.content.video);
        setTitle(json.content.title);
        setContent(json.content.content);
        setIsLoadingFailed(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoadingFailed(true);
      setIsLoading(false);
      console.error(error.message);
    }
  }

  useEffect(() => {
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const video = document.querySelector("video");
  }, []);

  return (
    <div className="recrutement-formateurs">
      <div>
        {video == null ? (
          <section className="recrutement-formateurs-section-1">
            <div></div>
          </section>
        ) : null}
      </div>
      <div style={{ backgroundColor: "#FFF" }}>
        <div className="recrutement-formateurs-background-image">
          <div className="recrutement-formateurs-background-cover-image">
            <div className="recrutement-formateurs-container">
              {isLoading ? (
                <div
                  style={{
                    height: "30vh",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: "#593da1",
                    }}
                  />
                </div>
              ) : (
                <div>
                  {isLoadingFailed ? (
                    <div style={{ textAlign: "center" }}>
                      <p style={{ color: "red", marginBottom: "30px" }}>
                        Échec de chargement
                      </p>
                      <button
                        style={{
                          border: "none",
                          padding: "10px 20px",
                          color: "#fff",
                          backgroundColor: "#593da1",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          setIsLoadingFailed(false);
                          setIsLoading(true);
                          getNews();
                        }}
                      >
                        Recharger
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div>
                        {video != null ? (
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "30px",
                            }}
                          >
                            <video controls>
                              <source src={video} type="video/mp4" />
                              Download the
                              <a href="/media/cc0-videos/flower.webm">WEBM</a>
                              or
                              <a href="/media/cc0-videos/flower.mp4">MP4</a>
                              video.
                            </video>
                          </div>
                        ) : null}
                      </div>
                      <h1>{title}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content,
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stagiaires;
