import "./Actuality.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

function Actuality() {
  let navigate = useNavigate();

  function navigateTo(id) {
    navigate("/news/" + id);
  }

  const [news, setNews] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingFailed, setIsLoadingFailed] = useState(false);

  async function getData() {
    const url = "https://apis.njamoun.org/api/news/synthesis";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      } else {
        const json = await response.json();
        setNews(json.content);
        setIsLoadingFailed(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoadingFailed(true);
      setIsLoading(false);
      console.error(error.message);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="actuality">
      <section className="actuality-section-1">
        <div></div>
      </section>
      <div className="actuality-dark-cover">
        <div className="actuality-container">
          <h1>
            ACTUALITÉS AU CENTRE DE FORMATION PROFESSIONNELLES ET DE LA
            PROMOTION DE L'EMPLOI NJA'MOUN
          </h1>
          <div>
            {isLoading ? (
              <div
                style={{
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress sx={{ color: "#593da1" }} />
              </div>
            ) : (
              <div>
                {isLoadingFailed ? (
                  <div style={{ textAlign: "center" }}>
                    <p style={{ color: "red", marginBottom: "30px" }}>
                      Échec de chargement
                    </p>
                    <button
                      style={{
                        border: "none",
                        padding: "10px 20px",
                        color: "#fff",
                        backgroundColor: "#593da1",
                        borderRadius: "4px",
                      }}
                      onClick={() => {
                        setIsLoadingFailed(false);
                        setIsLoading(true);
                        getData();
                      }}
                    >
                      Recharger
                    </button>
                  </div>
                ) : (
                  <section className="actuality-section-2">
                    {news.map((actuality) => (
                      <div
                        className="actuality-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo(actuality.id);
                        }}
                      >
                        <div
                          className="actuality-item-img"
                          style={{ backgroundImage: `url(${actuality.image})` }}
                        ></div>
                        <div className="actuality-item-text">
                          <p
                            className="actuality-item-p-1"
                            style={{ textTransform: "uppercase" }}
                          >
                            {actuality.title}
                          </p>
                          <div
                            className="actuality-item-p-2"
                            dangerouslySetInnerHTML={{
                              __html: actuality.introduction,
                            }}
                          ></div>
                          <div style={{ textAlign: "right", marginTop: 10 }}>
                            <button>Plus de détails</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </section>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Actuality;
